@forward 'ngx-bootstrap/datepicker/bs-datepicker.css'

fino-daterangepicker .input
  min-width: 200px

.bs-datepicker
  animation-name: slideDown
  animation-duration: var(--transition-duration)
  animation-timing-function: var(--transition-timing-function)
  animation-fill-mode: forwards
  animation-iteration-count: 1
  border-color: var(--border-color)
  border-radius: var(--radius)
  box-shadow: var(--dropdown-panel-shadow)
  margin-top: var(--dropdown-panel-margin-top)
  transform-origin: top center


.bs-datepicker-head,
.bs-datepicker-head button,
.bs-datepicker-body table td span.selected
  background-color: var(--primary)
  color: var(--primary-invert)

.bs-datepicker-body
  text-align: center

// Adjust datepicker padding
.bs-datepicker-container
  padding: 0

// Reduce gap between two calendars
.bs-datepicker-multiple + .bs-datepicker-multiple
  margin-left: -1px

// Set border radius to the outer edges of the calendar
.bs-datepicker-multiple
  .bs-datepicker-head
    border-radius: 3px 0 0 0
  .bs-datepicker-body
    border-radius: 0 0 0 3px
  + .bs-datepicker-multiple
    .bs-datepicker-head
      border-radius: 0 3px 0 0
    .bs-datepicker-body
      border-radius: 0 0 3px 0

.bs-datepicker-custom-range
  background-color: var(--background)

// Colorize the datepicker custom control buttons
.bs-datepicker-predefined-btns button
  --control-size: var(--size-small)
  --control-radius: var(--radius-small)
  --button-h: var(--scheme-h)
  --button-s: var(--scheme-s)
  --button-l: var(--scheme-main-l)
  --button-background-l: var(--scheme-main-l)
  --button-background-l-delta: 0%
  --button-active-background-l-delta: var(--active-background-l-delta)
  --button-active-border-l-delta: var(--active-border-l-delta)
  --button-border-l-delta: 0%
  --button-border-l: var(--border-l)
  --button-border-width: var(--control-border-width)
  --button-color-l: var(--text-strong-l)
  --button-focus-border-l-delta: var(--focus-border-l-delta)
  --button-hover-background-l-delta: var(--hover-background-l-delta)
  --button-hover-border-l-delta: var(--hover-border-l-delta)
  --button-outer-shadow-a: 0.05
  --button-outer-shadow-h: 0
  --button-outer-shadow-l: 20%
  --button-outer-shadow-s: 0%
  --button-padding-horizontal: 1em
  --button-padding-vertical: 0.5em
  background-color: hsl(var(--button-h),var(--button-s),calc(var(--button-background-l) + var(--button-background-l-delta)))
  border-color: var(--border)
  border-style: solid
  border-width: var(--button-border-width)
  box-shadow: 0 .0625em .125em hsla(var(--button-outer-shadow-h),var(--button-outer-shadow-s),var(--button-outer-shadow-l),var(--button-outer-shadow-a)),0 .125em .25em hsla(var(--button-outer-shadow-h),var(--button-outer-shadow-s),var(--button-outer-shadow-l),var(--button-outer-shadow-a))
  color: hsl(var(--button-h),var(--button-s),var(--button-color-l))
  cursor: pointer
  font-size: var(--control-size)
  font-weight: var(--button-weight)
  height: auto
  padding: calc(var(--button-padding-vertical) - var(--button-border-width)) calc(var(--button-padding-horizontal) - var(--button-border-width))
  transition-duration: var(--duration)
  transition-property: background-color, border-color, box-shadow, color
  &:active
    --button-background-l-delta: var(--button-active-background-l-delta)
    --button-border-l-delta: var(--button-active-border-l-delta)
    --button-outer-shadow-a: 0
  &:focus-visible
    --button-border-width: 1px
    border-color: hsl(var(--focus-h), var(--focus-s), var(--focus-l))
    box-shadow: var(--focus-shadow-size) hsla(var(--focus-h), var(--focus-s), var(--focus-l), var(--focus-shadow-alpha))
  &:hover
    --button-background-l-delta: var(--button-hover-background-l-delta)
    --button-border-l-delta: var(--button-hover-border-l-delta)
    background-color: inherit

