/// Forward derived variables with custom overrides to be able
/// to use all available variables with one import like:
/// @use "variables" as vars

// --------------------------------------------------------
// Initial variables
// --------------------------------------------------------
//
// Here we define all initial variables.
// These variables are used to derive other variables and
// are also used in the components.
:root
  // fino color palette
  --fino-digital-primary: #F7C708
  --fino-digital-secondary: #FCE99C
  --fino-digital-background: #092940
  --fino-kontowechsel-primary: #2678D4
  --fino-kontowechsel-secondary: #79ADE7
  --fino-analytics-primary: #F7C708
  --fino-analytics-secondary: #FFFFFF
  --fino-ident-primary: #E464F3
  --fino-ident-secondary: #552067

  // ------------------------------------
  // Bank Card / Icon / Search
  // ------------------------------------
  --bank-card-background-color: var(--scheme-main)
  --bank-card-border-radius: var(--radius)
  --bank-card-image-background-color: var(--scheme-main)
  --bank-card-image-radius: var(--radius)
  --bank-card-min-height: 150px
  --bank-card-opacity-disabled: 0.75
  --bank-card-padding: 1rem
  --bank-card-width: 300px
  --bank-icon-size: 48px
  --bank-search-image-size: 24px
  --bank-search-image-background-color: var(--scheme-main)
  --bank-search-option-padding: 0.5rem 1rem

  // ------------------------------------
  // Badge
  // ------------------------------------
  --badge-font-size: .65rem
  --badge-height: 14px
  --badge-padding: .05rem .15rem
  --badge-shadow: 0 0 0 2px var(--scheme-main)

  // ------------------------------------
  // Border / Box
  // ------------------------------------
  --border-width: 1px
  --box-radius: var(--radius)
  --box-shadow: var(--shadow)

  // ------------------------------------
  // Button
  // ------------------------------------
  --button-border-radius: var(--radius)
  --button-min-width: 12rem
  --button-padding-horizonal: 2rem
  --button-padding-vertical: 1rem
  --button-text-decoration: none
  --button-transition-duration: var(--transition-duration)
  --button-transition-timing-function: var(--transition-timing-function)

  // ------------------------------------
  // Checkbox / Checkradio
  // ------------------------------------
  --checkbox-checked-color: var(--primary)
  --checkbox-checked-width: 3px

  // ------------------------------------
  // Collapse
  // ------------------------------------
  --collapse-transition-speed: .2s
  --collapse-transition-style: ease

  // ------------------------------------
  // Colors (layout areas)
  // ------------------------------------
  --body-background-color: var(--background)
  --content-background-color: var(--background)
  --footer-background-color: var(--background)
  --header-background-color: var(--background)
  --sidebar-background-color: var(--background)

  // ------------------------------------
  // Divider
  // ------------------------------------
  --divider-background-color: var(--border)
  --divider-font-size: var(--size-7)
  --divider-margin-inner-size: 10px
  --divider-margin-size: 25px

  // ------------------------------------
  // Dropdown
  // ------------------------------------
  --dropdown-item-disabled-alpha: 0.5
  --dropdown-panel-header-height: 34px
  --dropdown-panel-items-height: 48px
  --dropdown-panel-items-max-height: calc(var(--dropdown-panel-items-height) * var(--dropdown-panel-visible-items) + var(--dropdown-panel-header-height) + 2*var(--input-radius))
  --dropdown-panel-items-padding: var(--input-radius) 0
  --dropdown-panel-margin-top: 0.5em
  --dropdown-panel-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)
  --dropdown-panel-visible-items: 6

  // ------------------------------------
  // Error Message
  // ------------------------------------
  --error-message-padding-vertical: 0.5em
  --error-message-padding-horizontal: 1em
  --error-message-padding: var(--error-message-padding-vertical) var(--error-message-padding-horizontal)

  // ------------------------------------
  // Float Label
  // ------------------------------------
  --float-label-background-color: var(--input-background-color)
  --float-label-color: var(--grey)
  --float-label-transition: var(--transition)
  --float-label-scale: 0.75

  // ------------------------------------
  // Footer
  // ------------------------------------
  --footer-padding: 1.5rem
  --footer-font-size: var(--size-7)
  --footer-color: hsl(var(--dark-h), var(--dark-s), var(--dark-l))

  // ------------------------------------
  // Header
  // ------------------------------------
  --header-height: 64px
  --header-height-mobile: 48px
  --header-margin-bottom: 1.5rem
  --header-margin-bottom-mobile: 1rem
  --header-title-margin-bottom: 0.5rem
  --header-title-small-margin-left: 0.75rem

  // ------------------------------------
  // Input
  // ------------------------------------
  --input-danger-border-color: var(--danger)
  --input-danger-box-shadow-color: hsla(var(--danger-h), var(--danger-s), var(--danger-l), 0.25)
  --input-danger-box-shadow-size: var(--focus-shadow-size)
  --input-danger-color: var(--danger)
  --input-disabled-background-color: var(--background)
  --input-disabled-border-color: var(--background)
  --input-disabled-color: var(--text-light)
  --input-disabled-placeholder-color: var(--text-lighter)
  --input-focus-color: var(--grey-darker)
  --input-hover-border-color: var(--grey-light)
  --input-hover-color: var(--grey-darker)
  --input-icon-active-color: var(--grey)
  --input-icon-color: var(--grey-lighter)
  --input-placeholder-color: var(--grey)
  --input-radius: var(--radius)

  // ------------------------------------
  // Navbar
  // ------------------------------------
  --navbar-divider-background-color: var(--grey-lighter)
  --navbar-divider-height: 1px
  --navbar-dropdown-arrow: var(--navbar-item-color)
  --navbar-end-spacing: 0.75rem
  --navbar-height-mobile: var(--header-height-mobile)
  --navbar-height: var(--header-height)
  --navbar-padding-horizontal: 0
  --navbar-padding-vertical: 0

  // ------------------------------------
  // Layout
  // ------------------------------------
  --layout-border: 0
  --layout-frame-border-radius: 8px
  --layout-frame-border: var(--layout-border)
  --layout-frame-content-border-radius: 6px
  --layout-frame-padding: 6px
  --layout-shadow: var(--shadow)
  --layout-content-background-color: var(--content-background-color)
  --layout-content-border: var(--layout-border)
  --layout-content-border-radius: 0
  --layout-content-footer-background-color: var(--footer-background-color)
  --layout-content-shadow: none
  --layout-footer-background-color: var(--footer-background-color)
  --layout-header-background-color: var(--header-background-color)
  --layout-header-height: var(--header-height)
  --layout-header-height-mobile: var(--header-height-mobile)
  --layout-header-navbar-padding: var(--navbar-padding-vertical) var(--navbar-padding-horizontal)
  --layout-sidebar-background-color: var(--sidebar-background-color)
  --layout-sidebar-background-image: var(--sidebar-background-image)
  --layout-sidebar-shadow: none
  --layout-sidebar-width: var(--sidebar-width)
  --layout-sidebar-width-minimized: var(--sidebar-width-minimized)

  // ------------------------------------
  // Link
  // ------------------------------------
  --link-visited: var(--link-text)

  // ------------------------------------
  // List
  // ------------------------------------
  --list-color: var(--text)
  --list-item-description-color: var(--text-light)
  --list-item-divider-color: var(--border)
  --list-item-hover-color: var(--scheme-main-bis)
  --list-item-image-margin: 0.75em
  --list-item-padding: 0.75em
  --list-item-title-color: var(--text-strong)
  --list-item-title-weight: var(--weight-semibold)

  // ------------------------------------
  // Loading Bar
  // ------------------------------------
  --loading-bar-color: var(--primary)
  --loading-bar-height: 2px
  --loading-bar-spinner-display: none
  --loading-bar-spinner-right: 10px
  --loading-bar-spinner-left: unset

  // ------------------------------------
  // Logo
  // ------------------------------------
  --logo-padding: 0.5rem
  --logo-padding-mobile: calc(var(--logo-padding) / 2)

  // ------------------------------------
  // Message box
  // ------------------------------------
  --message-box-shadow: 0 1px 6px -1px rgba(0, 9, 61, .16)
  --message-body-border-width: 1px
  --message-body-padding: 0.5rem 0.75rem
  --message-body-padding: 1.25em 1.5em
  --message-header-background-color: transparent

  // ------------------------------------
  // Modal
  // ------------------------------------
  --modal-box-shadow: var(--shadow)
  --modal-footer-box-shadow: var(--shadow-invert)
  --modal-padding: 1.5rem
  --modal-min-width: auto
  --modal-max-width: 640px
  --modal-width: min(100vw, 40rem)
  --modal-z-index: 110

  // ------------------------------------
  // Panel
  // ------------------------------------
  --panel-background-color: var(--scheme-main-bis)
  --panel-content-background-color: var(--scheme-main)
  --panel-content-border: none
  --panel-content-border-radius: var(--radius-medium)
  --panel-content-box-shadow: 0 0 var(--scheme-main), 0 0 var(--scheme-main), 0px 0px 2px 0px color-mix(in srgb, #000 calc(0.08 * 100%), transparent), 0px 1px 2px 0px color-mix(in srgb, #000 calc(0.06 * 100%), transparent), 0px 0px 0px 1px color-mix(in srgb, #000 calc(0.04 * 100%), transparent)
  --panel-content-padding: 1em
  --panel-gap: 2rem
  --panel-header-padding: 0.75em 1em
  --panel-padding: 0.25em
  --panel-shadow: none

  // ------------------------------------
  // Switch
  // ------------------------------------
  --switch-background: var(--grey-light)
  --switch-background-active: var(--primary)
  --switch-border: .1rem solid var(--switch-border-color)
  --switch-border-color: transparent
  --switch-focus: none
  --switch-focus-border-color: hsl(var(--input-focus-h), var(--input-focus-s), var(--input-focus-l))
  --switch-focus-box-shadow: var(--input-focus-shadow-size) hsla(var(--input-focus-h), var(--input-focus-s), var(--input-focus-l), var(--input-focus-shadow-alpha))
  --switch-paddle-background: var(--scheme-main)
  --switch-paddle-background-active: var(--primary)
  --switch-paddle-transition: var(--transition)
  --switch-outlined-background: var(--scheme-main)
  --switch-outlined-background-active: var(--scheme-main)
  --switch-outlined-border-color: var(--grey-light)
  --switch-outlined-border-color-active: var(--primary)
  --switch-outlined-paddle-background: var(--grey-light)
  --switch-outlined-paddle-background-active: var(--primary)
  --switch-outlined-paddle-box-shadow: 0 2px 10px 0 rgba(0,0,0,.16), 0 0 2px 0 rgba(0,0,0,.1)
  --switch-radius: var(--radius)

  // ------------------------------------
  // Section
  // ------------------------------------
  --section-padding: 1.5rem
  --section-padding-desktop: 3rem

  // ------------------------------------
  // Shadow
  // ------------------------------------
  --shadow: 0 0.5em 1em -0.125em hsla(var(--shadow-h), var(--shadow-s), var(--shadow-l), 0.1),0 0px 0 1px hsla(var(--shadow-h), var(--shadow-s), var(--shadow-l), 0.02)
  --shadow-invert: 0 -.5em 1em .125em hsla(var(--shadow-h), var(--shadow-s), var(--shadow-l), .1), 0 0px 0 1px hsla(var(--shadow-h), var(--shadow-s), var(--shadow-l), .02)

  // ------------------------------------
  // Sidebar
  // ------------------------------------
  --sidebar-width: 300px
  --sidebar-width-minimized: 60px

  // ------------------------------------
  // Table
  // ------------------------------------
  --table-head-cell-border-width: 0 0 1px
  --table-foot-cell-border-width: 1px 0 0
  --table-progress-bar-height: 2px
  --table-progress-bar-background-color: var(--scheme-main)
  --table-progress-value-background-color: var(--primary)
  --table-row-hover-background-color: var(--grey-lighter)

  // ------------------------------------
  // Timeline
  // ------------------------------------
  --timeline-marker-size: 12px
  --timeline-marker-color: var(--grey-lighter)
  --timeline-marker-icon-size: 24px
  --timeline-marker-border-size: 1px
  --timeline-marker-border: var(--timeline-marker-border-size) solid var(--timeline-marker-color)
  --timeline-line-color: var(--grey-lighter)
  --timeline-line-width: 1px
  --timeline-line-style: solid
  --timeline-line: var(--timeline-line-width) var(--timeline-line-style) var(--timeline-line-color)
  --timeline-content-padding: 1em 0 0 2em
  --timeline-rtl-content-padding: 1em 2em 0 0
  --timeline-icon-size: .75rem
  --timeline-header-width: 4em
  --timeline-item-padding-left: calc(var(--timeline-header-width) * 0.5)
  --timeline-item-padding-bottom: 2em
  --timeline-dimensions: 16 24 32 48 64 96 128

  // ------------------------------------
  // Tooltip
  // ------------------------------------
  --tooltip-padding: .75em 1em
  --tooltip-radius: 6px

  // ------------------------------------
  // Transition
  // ------------------------------------
  --easing: ease-out
  --speed: 120ms
  --transition-delay: 0
  --transition-duration: var(--speed)
  --transition-timing-function: var(--easing)
  --transition: all var(--speed) var(--easing)
