.is-selected > .radio,
.radio.is-selected
  &::after
    opacity: 1
    transform: scale(1,1)

.radio
  --control-height: 20px
  --toggle-gap: 5px
  display: inline-block
  position: relative
  padding-left: calc(var(--control-height) * 1.5)

  &::before
    content: ''
    display: block
    position: absolute
    top: 0px
    left: 0px
    border-radius: 50%
    margin-right: 0.5em
    width: var(--control-height)
    height: var(--control-height)
    border: 1px solid var(--control-border-color, var(--border))
    background: var(--scheme-main)

  &::after
    content: ''
    display: block
    width: calc(var(--control-height) / 2)
    height: calc(var(--control-height) / 2)
    background: var(--primary)
    position: absolute
    border-radius: 50%
    top: var(--toggle-gap)
    left: var(--toggle-gap)
    opacity: 0
    transform: scale(0,0)
    transition: all .2s cubic-bezier(0.64, 0.57, 0.67, 1.53)

  &.align-center
    &::before,
    &::after
      top: 50%

    &::before
      margin-top: calc(var(--control-height) / -2)

    &::after
      margin-top: calc(var(--control-height) / -2 + var(--toggle-gap))
