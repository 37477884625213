@use 'bulma/sass/form/shared' as form

.toggle
  // padding values to align with button height
  // is-medium aligns to input height
  --toggle-width: calc(100% / var(--toggle-options))
  --toggle-active-index: 0
  --toggle-background-color: var(--scheme-main-bis)
  --toggle-option-gap: 0.25em
  --toggle-option-padding: 3em
  --toggle-switch-background-color: var(--scheme-main)
  --toggle-switch-border-radius: var(--input-radius)
  --toggle-switch-box-shadow: var(--shadow)

  // Apply shared styles from form input
  @include form.input

  // Override shared styles
  background-color: var(--toggle-background-color)
  display: flex
  overflow: hidden
  position: relative
  transition: all var(--transition-duration) var(--transition-timing-function)

  &:focus-visible
    border-color: hsl(var(--focus-h), var(--focus-s), var(--focus-l))
    box-shadow: var(--focus-shadow-size) hsla(var(--focus-h), var(--focus-s), var(--focus-l), var(--focus-shadow-alpha))

  &.is-small
    --label-font-size: var(--size-small)

  &.no-wrap
    .option .label
      white-space: nowrap

  .option
    display: flex
    align-items: center
    justify-content: center
    height: 100%
    padding-left: var(--toggle-option-padding)
    padding-right: var(--toggle-option-padding)
    width: var(--toggle-width)
    z-index: 1

    &:not(.is-active)
      opacity: 0.75

      &:hover
        opacity: 1

  .switch
    background-color: var(--toggle-switch-background-color)
    border-radius: var(--toggle-switch-border-radius)
    box-shadow: var(--toggle-switch-box-shadow)
    cursor: pointer
    display: block
    position: absolute
    top: var(--toggle-option-gap)
    bottom: var(--toggle-option-gap)
    width: calc(var(--toggle-width) - 2 * var(--toggle-option-gap))
    left: calc(var(--toggle-width) * var(--toggle-active-index) + var(--toggle-option-gap))
    transition: left var(--transition-duration) var(--transition-timing-function)
    z-index: 0
