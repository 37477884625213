@use '../../mixins' as *

// Apply scope related variables to navbar
.navbar
  --navbar-height: var(--header-height)
  --navbar-item-img-max-height: calc(var(--navbar-height) - var(--logo-padding, 0rem) * 2)

  // Resize header on mobile
  +mobile
    --navbar-height: var(--navbar-height-mobile)
    --logo-padding: var(--logo-padding-mobile)

.navbar-brand
  .navbar-logo
    gap: var(--navbar-logo-gap, 0.75rem)
    text-decoration: none

  // Remove padding from navbar brand logo
  .navbar-item:has(.navbar-logo)
    padding-top: var(--logo-padding, 0)
    padding-bottom: var(--logo-padding, 0)

  // Avoid background color on hover for navbar brand
  a.navbar-item:hover
    background-color: transparent

  // Avoid line breaks for navbar title
  .title
    white-space: pre

.navbar-menu
  // Hide empty buttons
  .buttons:empty
    display: none

.navbar-start,
.navbar-end
  align-items: center

// Spacing for navbar end
.navbar-end
  +desktop
    gap: var(--navbar-end-spacing, 0)

.navbar-item,
.navbar-link
  border-radius: var(--navbar-item-radius, var(--radius))

.navbar-link
  img.icon
    max-height: 1rem
    +desktop
      margin-left: 0.5rem

.navbar-logo
  border-radius: 0
  &.is-svg
    width: 100%

// Remove padding from navbar items that contain a link
+touch
  .layout-header .navbar-item:has(>.navbar-link)
    padding: 0

  .navbar-dropdown .navbar-item
    padding: 0.5rem 0.75rem
