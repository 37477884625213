.is-colorpicker
  position: relative

  .color-value
    border-radius: var(--radius)
    position: absolute
    height: calc(var(--input-height) - 1em)
    width: calc(var(--input-height) - 1em)
    right: 0.5em
    top: 0.5em
    z-index: 3

  .color-picker
    border: 0 none
    box-shadow: var(--shadow)
    .arrow,
    .arrow::after
      display: none
    .hue-alpha
      border: 0 none
    .box
      box-shadow: none
      padding: 4px 8px !important
      margin-bottom: 4px
      &:has(>input)
        border: 1px solid var(--border)
        border-radius: var(--radius)
        padding: 4px 8px
      input
        border: 0 none
        outline: none
    .hex-text,
    .hsla-text,
    .rgba-text
      .box input
        border: 0 none
      .box + .box
        border: 0 none
    .hsla-text,
    .rgba-text
      .box input
        margin-right: 4px
        &:not(:first-child)
          border-left: 1px solid var(--button-border-color)
        div
          margin-right: 4px
    .type-policy
      top: 224px
    .button-area
      padding: 0 8px 8px
      button
        margin-left: auto
