@use '../../mixins' as *

// Transition border and box-shadow
.input,
.textarea
  box-shadow: none
  transition-property: border, box-shadow
  transition-duration: var(--transition-duration)
  transition-timing-function: var(--transition-timing-function)
  will-change: border, box-shadow

  &[readonly]
    color: var(--input-disabled-color)

.control
  // Hide empty control elements
  &:empty
    display: none

  // Icon addons
  &.has-icons-left,
  &.has-icons-right
    .icon
      padding: 1em

  &.has-icons-left
    .input,
    .select
      // Double the left padding for controls with an icon on the left
      padding-left: calc(var(--float-label-padding-horizontal) * 2)

    .label
      // Double the left padding for labels with an icon on the left and push label 1px to the right
      left: calc(var(--float-label-padding-horizontal) * 2 + 1px)

  .is-danger
    ~ .icon
      color: var(--danger)

  .label
    font-weight: 400

  .absolute-content
    height: var(--input-height)
    right: 0
    top: 0
    display: flex
    align-items: center
    position: absolute

// Align button height to input control in a grouped field
.field.is-grouped
  .control > [fino-button]
    height: 100%

// Align field and help
.field:not(:last-child)
  &:has(~fino-input-error),
  &:has(~.help)
    margin-bottom: 0

.input-columns
  &:not(:last-child)
    margin-bottom: 0 !important
  +mobile
    display: block
    .column:not(:last-child)
      padding-bottom: 0 !important
