//
// fade-in
//
.fade-in
  animation-name: fadeInOpacity
  animation-duration: var(--transition-duration)
  animation-timing-function: var(--transition-timing-function)
  animation-iteration-count: 1

@keyframes fadeInOpacity
  0%
    opacity: 0
  100%
    opacity: 1

//
// has-shake-horizontal
//
.has-shake-horizontal
  animation: shakeHorizontal 0.8s cubic-bezier(0.455, 0.03, 0.515, 0.955) both

@keyframes shakeHorizontal
  0%, 100%
    transform: translateX(0)
  10%, 30%, 50%, 70%
    transform: translateX(-5px)
  20%, 40%, 60%
    transform: translateX(5px)
  80%
    transform: translateX(3px)
  90%
    transform: translateX(-3px)

//
// animate-scale-in
//
.animate-scale-in
  --index: 0
  animation-name: animateScaleIn
  animation-duration: 300ms
  animation-delay: calc(var(--index, 0) * 100ms)
  animation-fill-mode: both
  animation-timing-function: ease-in-out

@keyframes animateScaleIn
  0%
    opacity: 0
    transform: scale(0.6) translateY(-8px)
  100%
    opacity: 1

//
// animate-height
//
@keyframes animateHeight
  0%
    height: 0
  100%
    height: 100%

//
// animate-width
//
@keyframes animateWidth
  0%
    width: 0
  100%
    width: 100%

//
// On hover fade utility
// usage:
// 1) give the trigger element '.has-hover' class
// 2) give the hoverable element '.on-hover-visible' class to fade in
// or give the hoverable element '.on-hover-fade-right' class to fade and slide in from right
//
// NOTE: on devices without hover the hoverable element is always visible.
//
.has-hover
  // Hide that class until parent gets hovered
  .on-hover-visible
    opacity: 0
    transition: opacity var(--transition-duration) ease-in-out

  // Hide that class until parent gets hovered
  .on-hover-fade-right
    opacity: 0
    transition: all var(--transition-duration) ease-in-out
    transform: translateX(-1rem)

  // Hide that class until parent gets hovered
  .on-hover-fade-left
    opacity: 0
    transition: all var(--transition-duration) ease-in-out
    transform: translateX(1rem)

  // Hide that class until parent gets hovered
  .on-hover-fade-up
    opacity: 0
    transition: all var(--transition-duration) ease-in-out
    transform: translateY(1rem)

  // Hide that class until parent gets hovered
  .on-hover-fade-down
    opacity: 0
    transition: all var(--transition-duration) ease-in-out
    transform: translateY(-1rem)

  // Activate on-hover styles
  &:hover
    // Show on hover
    .on-hover-visible
      opacity: 1

    // Show and fade to right on hover
    .on-hover-fade-right,
    .on-hover-fade-left,
    .on-hover-fade-up,
    .on-hover-fade-down
      opacity: 1
      transform: translateX(0)

  // Always be visible on touch devices
  @media(hover: none)
    .on-hover-visible
      opacity: 1
    .on-hover-fade-right,
    .on-hover-fade-left,
    .on-hover-fade-up,
    .on-hover-fade-down
      opacity: 1
      transform: translateX(0)

//
// growDown animation
//
@keyframes growDown
  0%
    transform: scaleY(0)
  80%
    transform: scaleY(1.1)
  100%
    transform: scaleY(1)

//
// slide down animation
//
@keyframes slideDown
  0%
    transform: translateY(-1rem)
  100%
    transform: translateY(0)

//
// toggle flip animation
//
.has-toggle-flip
  position: relative
  transform: transform 1s
  transform-style: preserve-3d

  .toggle-flip-side
    width: 100%
    height: 100%
    position: absolute
    backface-visibility: hidden
    cursor: pointer
    // user-select: none

    &.is-front
      transform: rotateY(0deg)

    &.is-back
      transform: rotateY(-180deg)
