// Multiline text in element
.is-multiline
  height: auto
  text-align: left
  white-space: pre-line

// Comma separated list
.is-comma-separated
  & + .is-comma-separated::before
    content: ", "


.has-text-overflow
  overflow: hidden
  overflow-wrap: break-word
  word-break: break-word
  white-space: pre-line

.has-text-overflow-ellipsis
  display: block
  overflow: hidden
  text-overflow: ellipsis
  white-space: nowrap

.has-text-pruned
  display: -webkit-box
  text-overflow: ellipsis
  overflow: hidden
  overflow-wrap: break-word
  word-break: break-word
  -webkit-line-clamp: 2 // number of lines to show
  -webkit-box-orient: vertical

.has-text-gradient
  -webkit-background-clip: text
  -webkit-text-fill-color: transparent
  background-color: var(--primary)
  background-image: linear-gradient(90deg,var(--primary),var(--primary-dark))
  background-size: 100%

.has-text-line-through
  text-decoration: line-through
