fino-icon > *
  // align icon with text
  line-height: 1
  // prevent native tooltips from svg titles
  pointer-events: none

.is-tooltip-icon
  --icon-dimensions: 1.25rem

.icon
  font-size: var(--icon-font-size, 1rem)
  &.has-hover
    color: rgba(var(--scheme-invert-rgb, 10, 10, 10), 0.2)
    cursor: pointer
    &:hover
      color: rgba(var(--scheme-invert-rgb, 10, 10, 10), 0.3)

.icon-text
  &:has(.icon.is-small)
    --icon-text-spacing: 0.25rem

// Dont wrap icon and text in dropdowns
.dropdown
  .icon-text
    flex-wrap: nowrap
