@forward 'ngx-modalview/styles/ngx-modal.scss'
@use '../../mixins' as *

// Fixes the modal scrolling issue where opening it resets the scroll position.
// Normally, fixed modal and hidden body overflow cause body shrinkage and reset scrolling.
// Setting body overflow to unset preserves its size and maintains the scroll position.
html:has(body.modal-open)
  overflow: hidden

body.modal-open
  overflow: unset

// Modal adjustments
.modal
  // Important to override ngx-modalview styles to center the modal
  display: flex !important
  // Overlap the modal on top of other elements
  z-index: 100

  +mobile
    --mobile-padding: 1rem

  .modal-content
    box-shadow: var(--modal-box-shadow)
    margin-top: 0
    width: var(--modal-width)
    min-width: var(--modal-min-width)
    max-width: var(--modal-max-width)
    position: relative // required for modal-close to be positioned correctly

    +mobile
      width: auto

  .modal-close
    background-color: rgba(var(--scheme-invert-rgb, 10, 10, 10),.2)
    position: absolute
    top: 1rem
    right: 1rem
    z-index: 110
    &:hover, &:focus
      background-color: rgba(var(--scheme-invert-rgb, 10, 10, 10),.3)

  .modal-header,
  .modal-body,
  .modal-footer
    border: 0
    height: auto
    padding: var(--modal-padding)

  .modal-header
    justify-content: flex-start
    ~ .modal-body
      padding-top: 0

  .modal-footer
    justify-content: flex-end

  .modal-body:has(~.modal-footer)
    padding-bottom: 0

  // Modal as sidebar
  .sidebar
    height: 100%
    width: auto
    position: absolute
    top: 0
    bottom: 0
    left: auto
    margin: 0 0 0 auto
    box-shadow: 0 7px 8px -4px #0003, 0 13px 19px 2px #00000024, 0 5px 24px 4px #0000001f

    &.has-gap
      height: calc(100% - 2rem)
      min-height: calc(100% - 2rem)
      margin: 1rem 1rem 1rem auto

    &.is-right
      right: 0

    &.animate
      animation: fromRight .2s linear

    .modal-content
      padding: 0
      margin: 0
      max-height: none
      height: 100%
      overflow: auto

      &:has:not(.has-gap)
        border-top-right-radius: 0
        border-bottom-right-radius: 0

.modal-card-title
  flex-shrink: 1 // Allow card title to shrink and grow with the content

.modal-card-footer
  box-shadow: var(--modal-footer-box-shadow)

@keyframes fromRight
  0%
    right: -100%
  to
    right: 0
