@use 'bulma/sass/utilities/derived-variables' as vars
@use '../mixins' as *

=switch-size($size)
  $switch-height: $size * 1.5
  $switch-width: $switch-height * 2
  $switch-paddle-offset: 0.25rem
  $paddle-height: $switch-height - ($switch-paddle-offset * 2)
  $paddle-width: $switch-height - ($switch-paddle-offset * 2)
  $paddle-active-offest: $switch-width - $paddle-width - ($switch-paddle-offset * 1.5)

  + label
    position: relative
    display: inline-flex
    align-items: center
    justify-content: flex-start
    font-size: $size
    height: auto
    line-height: var(--control-line-height)
    padding-left: $switch-width + .5
    padding-top: calc( #{$switch-height} * 0.5 )
    cursor: pointer

    &:has(>span)
      padding-top: 0 !important

    &::before
      position: absolute
      display: block
      top: calc( 50% - #{$switch-height} * 0.5 )
      left: 0
      width: $switch-width
      height: $switch-height
      border: var(--switch-border)
      border-radius: var(--switch-radius)
      background: var(--switch-background)
      content: ''

    &::after
      display: block
      position: absolute
      top: calc( 50% - #{$paddle-height} * 0.5 )
      left: $switch-paddle-offset
      width: $paddle-width
      height: $paddle-height
      transform: translate3d(0, 0, 0)
      border-radius: var(--switch-radius)
      background: var(--switch-paddle-background)
      transition: var(--switch-paddle-transition)
      content: ''

    .switch-active,
    .switch-inactive
      font-size: $size - 0.1
      z-index: 1
      margin-top: -4px
    &.has-text-inside
      .switch-inactive
        margin-left: -($paddle-active-offest + 0.3)
      .switch-active
        margin-left: -($paddle-active-offest * 2)


  &.is-rtl
    + label
      padding-left: 0
      padding-right: $switch-width + .5
      &::before
        left: auto
        right: 0
      &::after
        left: auto
        right: $paddle-active-offest

  &:checked
    + label
      &::before
        background: var(--switch-background-active)
      &::after
        left: $paddle-active-offest
    &.is-rtl
      + label
        &::after
          left: auto
          right: $switch-paddle-offset

  &.is-outlined
    + label
      &::before
        background-color: var(--switch-outlined-background)
        border-color: var(--switch-outlined-border-color)
      &::after
        background: var(--switch-outlined-paddle-background)
        box-shadow: var(--switch-outlined-paddle-box-shadow)
    &:checked
      + label
        &::before
          background-color: var(--switch-outlined-background-active)
          border-color: var(--switch-outlined-border-color-active)
        &::after
          background: var(--switch-outlined-paddle-background-active)
          box-shadow: var(--switch-outlined-paddle-box-shadow)

  &.is-thin
    + label
      &::before
        top: divide($switch-height, 2.75)
        height: divide($switch-height, 4)
      &::after
        box-shadow: 0px 0px 3px var(--grey)

  &.is-rounded
    + label
      &::before
        border-radius: calc(var(--radius-large) * 4)
      &::after
        border-radius: 50%


.switch[type="checkbox"]
  display: inline-block
  opacity: 0
  outline: 0
  position: absolute
  user-select: none

  &:focus,
  &:focus-within
    + label
      &::before
        box-shadow: var(--switch-focus-box-shadow)
        outline: var(--switch-focus)
      &::after
        outline: var(--switch-focus)

  &[disabled]
    cursor: not-allowed
    + label
      opacity: 0.5
      &::before
        opacity: 0.5
      &::after
        opacity: 0.5
      &:hover
        cursor: not-allowed

    // Modify readonly styles to prevent opacity change to label
    &[readonly] + label
      opacity: 1

  +switch-size(vars.$size-normal)
  &.is-small
    +switch-size(vars.$size-small)
  &.is-medium
    +switch-size(vars.$size-medium)
  &.is-large
    +switch-size(vars.$size-large)

  @each $name, $pair in vars.$colors
    &.is-#{$name}
      + label
        .switch-active
          display: none
        .switch-inactive
          display: inline-block
      &:checked
        + label
          &::before
            background: var(--#{$name})
          .switch-active
            display: inline-block
          .switch-inactive
            display: none
      &.is-outlined
        &:checked
          + label
            &::before
              background-color: transparent
              border-color: var(--#{$name}) !important
            &::after
              background: var(--#{$name})
      &.is-thin
        &.is-outlined
          + label
            &::after
              box-shadow: none
    &.is-unchecked-#{$name}
      + label
        &::before
          background: var(--#{$name})
      &.is-outlined
        + label
          &::before
            background-color: transparent
            border-color: var(--#{$name}) !important
          &::after
            background: var(--#{$name})

.field-body
  .switch[type="checkbox"]
    + label
      margin-top: .375em
