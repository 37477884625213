=float-label-container
  position: relative

=float-label
  align-items: center
  background: none
  color: var(--float-label-color)
  cursor: pointer
  display: inline-flex
  position: absolute
  top: 0
  left: 0
  height: 100%
  padding: var(--control-padding-vertical) var(--control-padding-horizontal)
  margin: 0
  opacity: 1
  overflow: hidden
  text-align: start
  text-overflow: ellipsis
  white-space: nowrap
  pointer-events: none
  transform-origin: 0 0
  transition: var(--float-label-transition)
  z-index: 5
  @content

  &::after
    position: absolute
    inset: .5rem .25rem
    z-index: -1
    height: 1.5em
    content: ""
    background-color: var(--float-label-background-color)
    border-radius: var(--input-radius)

=float-label-input($height: var(--input-height))
  // Height and padding to align floated label with input
  height: $height
  min-height: $height
  padding-top: calc(var(--control-padding-vertical) + 1em)
  padding-bottom: calc(var(--control-padding-vertical))
  margin: 0

  &::placeholder
    transition: var(--float-label-transition)

  // Empty, not focused input -> placeholder visible
  &:placeholder-shown:not(:focus),
  &:not(.has-value):not(:focus)
    // Hide default placeholder
    &::placeholder
      opacity: 0
      display: none

    &:-ms-input-placeholder
      color: transparent

    // Replace default placeholder with unfloated label
    & ~ label
      +float-label-position()
      @content

  // Sizes
  &.is-small ~ label
    font-size: var(--size-small)
  &.is-medium ~ label
    font-size: var(--size-medium)
  &.is-large ~ label
    font-size: var(--size-large)

  // Float label (initial state)
  ~ .label
    +float-label-position(true)

=float-label-position($floated: false)
  @if $floated
    // When floated, scale label and move it to the top left corner
    // Some notes on the following calculations:
    // - The label is scaled down to `var(--float-label-scale)` (0.75) of its original size
    // - The padding is adjusted to align the label with the input
    // - The padding-bottom is increased by the factor of the scale to make space for the label
    // - The padding-left is increased by 1px to prevent the label from overlapping the input border
    // - The padding-left is increased by the factor of the scale to shift the label to align with the input
    transform: scale(var(--float-label-scale))
    padding-bottom: calc(var(--control-padding-vertical) + var(--float-label-scale) * 1em)
    padding-left: calc(var(--control-padding-horizontal) + 1px + (1 - var(--float-label-scale)) * 1em)
  @else
    // When not floated, reset label position
    transform: none
    padding-bottom: calc(var(--control-padding-vertical))
    padding-left: calc(var(--control-padding-horizontal))
