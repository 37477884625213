@use '../../mixins/float-label' as *

.has-float-label
  +float-label-container

  .label
    +float-label

  .input,
  .select,
  .textarea
    +float-label-input

  .textarea
    height: auto
    min-height: var(--input-height)
    ~ .label
      --float-label-background-color: var(--scheme-main)
      align-items: flex-start
      text-wrap: auto
