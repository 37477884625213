@use 'bulma/sass/utilities/derived-variables' as vars
@use 'sass:math'

// Helper to set the size of the checkbox
=container-size($size)
  $height: calc($size * 1.5)
  min-height: $height

// Use a mixin to define all ratios in the same spot
=checkbox-size($size)
  $frameSize: calc($size * 1.5)
  $frameSizeBlock: calc($size * 1.25)
  $padSize: calc($size * 2.25)
  $width: calc($size / 2)
  $height: $size
  $top: calc($size / 5)
  $left: calc($size / 2)

  +container-size($size)

  + label
    font-size: $size
    padding-left: $padSize
    text-align: left

    &::before
      width: $frameSize
      height: $frameSize

    &::after
      width: $width
      height: $height
      top: $top
      left: $left

    & + label
      margin-left: $padSize

  &.is-block
    + label
      &::before
        width: $frameSizeBlock
        height: $frameSizeBlock
        left: .175rem
        top: .175rem

      &::after
        top: .2rem + calc($size * 0.125)
        left: .2rem + calc($size * 0.45)

  &.is-rtl
    + label
      padding-left: 0
      padding-right: $padSize
      &::after
        left: auto
        right: $height

=radio-size($size)
  $newSize: calc($size * 1.5)
  $padSize: calc($size * 2)
  $height: $newSize
  $width: $newSize

  +container-size($size)

  + label
    font-size: $size
    padding-left: $padSize

    &::before,
    &::after
      width: $width
      height: $height

    & + label
      margin-left: $padSize

  &.is-rtl
    + label
      padding-right: $padSize

.has-checkradio
  +container-size(vars.$size-normal)
  &.is-small
    +container-size(vars.$size-small)
  &.is-medium
    +container-size(vars.$size-medium)
  &.is-large
    +container-size(vars.$size-large)

.is-checkradio[type="radio"],
.is-checkradio[type="checkbox"]
  outline: 0
  user-select: none
  display: inline-block
  position: absolute
  opacity: 0

  + label
    border-radius: var(--input-radius)
    cursor: pointer
    display: flex
    flex-direction: column
    position: relative
    vertical-align: middle

    &:hover
      &::before
        animation-duration: 0.4s
        animation-fill-mode: both
        animation-name: hover-color

    &::before
      background-color: var(--input-background-color)
      border-color: var(--input-border-color)
      border-style: solid
      border-width: var(--control-border-width)
      content: ''
      left: 0
      position: absolute
      transition-property: border, box-shadow
      transition-duration: var(--transition-duration)
      transition-timing-function: var(--transition-timing-function)

    &::after
      content: ''
      display: none
      position: absolute
      transform: border
      transition: var(--transition-duration)
      transition-timing-function: var(--transition-timing-function)

  &.is-rtl
    + label
      margin-right: 0rem
      margin-left: 0.5rem
      &::before
        left: auto
        right: 0

  &:hover:not([disabled])
    + label
      &::before
        border-color: var(--input-hover-border-color)

  &:focus,
  &:checked:focus,
  &:hover:focus
    + label
      &::before
        border-color: hsl(var(--input-focus-h), var(--input-focus-s), var(--input-focus-l))
        box-shadow: var(--input-focus-shadow-size) hsla(var(--input-focus-h), var(--input-focus-s), var(--input-focus-l), var(--input-focus-shadow-alpha))

  &:checked
    + label
      &::before
        border-color: var(--input-border-color)
    &[disabled]
      cursor: not-allowed
      + label
        opacity: 0.5
    + label
      &::before
        animation-name: none
      &::after
        display: inline-block

  &[disabled]
    cursor: not-allowed
    + label
      opacity: 0.5
      cursor: not-allowed
      &:hover,
      &::before,
      &::after
        cursor: not-allowed

    &:hover
      cursor: not-allowed
      &::before
        animation-name: none

    &::before,
    &::after
      cursor: not-allowed

  &[readonly]
    pointer-events: none
    + label
      pointer-events: none

  &.has-no-border
    + label
      &::before
        border: none !important

  &.is-block
    display: none !important
    + label
      width: 100% !important
      background: var(--input-background-color)
      color: var(--input-color)
      padding-right: .75em
    &:hover:not([disabled])
      + label
        background: var(--input-disabled-background-color)

  &.is-danger,
  &.has-error
    + label
      &::before
        --input-h: var(--danger-h)
        --input-s: var(--danger-s)
        --input-l: var(--danger-l)
        --input-focus-h: var(--danger-h)
        --input-focus-s: var(--danger-s)
        --input-focus-l: var(--danger-l)
        --input-border-l: var(--danger-l)

.is-checkradio[type="checkbox"]
  +checkbox-size(vars.$size-normal)
  &.is-small
    +checkbox-size(vars.$size-small)
  &.is-medium
    +checkbox-size(vars.$size-medium)
  &.is-large
    +checkbox-size(vars.$size-large)

  &.is-circle
    + label
      &::before
        border-radius: 50%

  @each $name, $pair in vars.$colors
    &.is-#{$name}
      &.has-background-color
        + label
          &::before
            border-color: transparent !important
            background-color: var(--#{$name}) !important
      &:hover:not([disabled])
        + label
          &::before
            border-color: var(--#{$name}) !important
      &:checked
        + label
          &::after
            border-color: var(--#{$name}) !important
        &.has-background-color
          + label
            &::before
              border-color: transparent !important
              background-color: var(--#{$name}) !important
            &::after
              border-color: var(--#{$name}-invert) !important
              background-color: var(--#{$name}) !important
      &.is-block
        &:hover:not([disabled])
          + label
            &::before,
            &::after
              border-color: var(--#{$name}) !important
        &:checked
          + label
            color: var(--#{$name}-invert)
            border-color: var(--#{$name}) !important
            background: var(--#{$name})
            &::after
              border-color: var(--#{$name}-invert) !important
          &:hover:not([disabled])
            --hover-background-color: #{bulmaVarLighteness($name, calc(#{var(--#{$name}-l)} - 2.5%))}
            --hover-border-color: #{bulmaVarLighteness($name, calc(#{var(--#{$name}-l)} - 5%))}
            + label
              background: var(--hover-background-color)
              &::before,
              &::after
                border-color: --hover-border-color

  + label
    &::before
      border-radius: var(--input-radius)

    &::after
      box-sizing: border-box
      transform: rotate(45deg)
      border-width: var(--checkbox-checked-width)
      border-style: solid
      border-color: var(--checkbox-checked-color)
      border-top: 0
      border-left: 0

  &:indeterminate
    @each $name, $pair in vars.$colors
      &.is-#{$name}
        + label
          &::after
            border-color: var(--#{$name})
    + label
      &::after
        display: inline-block
        transform: rotate(90deg)
        border-bottom: none

.is-checkradio[type="radio"]
  +radio-size(vars.$size-normal)
  &.is-small
    +radio-size(vars.$size-small)
  &.is-medium
    +radio-size(vars.$size-medium)
  &.is-large
    +radio-size(vars.$size-large)

  &:checked
    &.has-background-color
      + label
        &::before
          border-color: var(--text) !important
          background-color: var(--text) !important
        &::after
          border-color: var(--text) !important
          background-color: var(--text) !important

  &.is-rtl
    + label
      padding-left: 0
      &::after
        left: auto
        right: 0

  @each $name, $pair in vars.$colors
    &.is-#{$name}
      &.has-background-color
        + label
          &::before
            border-color: var(--#{$name}) !important
            background-color: var(--#{$name}) !important
      &:hover:not([disabled])
        + label
          &::before
            border-color: var(--#{$name}) !important
      &:checked
        + label
          &::after
            border-color: var(--#{$name}) !important
            background-color: var(--#{$name}) !important
        &.has-background-color
          + label
            &::before
              border-color: var(--#{$name}) !important
              background-color: var(--#{$name}) !important
            &::after
              border-color: var(--#{$name}-invert) !important
              background-color: var(--#{$name}-invert) !important

  + label
    &::before
      border-radius: 50%
    &::after
      border-radius: 50%
      background: var(--primary)
      left: 0
      transform: scale(0.5)


// Gap between horizontal radio buttons
.control
  .radio + .radio
    margin-left: 1em
