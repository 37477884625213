@use '../../mixins/float-label' as *

// Floating label adjustments
.has-float-label
  .ng-select
    &,
    &.ng-select-single,
    &.ng-select-multiple
      // Float label (focused)
      &.ng-select-focused .ng-select-container .ng-value-container .ng-placeholder
        +float-label-position(true)

      .ng-select-container
        +float-label-input

        // Float label (has value)
        &.ng-has-value
          .ng-value-container .ng-placeholder
            +float-label-position(true)

        // Placeholder (unfloated label)
        .ng-value-container
          .ng-placeholder
            color: var(--input-placeholder-color)
            +float-label
            +float-label-position()

          // align input to select-container
          .ng-input
            padding: 0
            position: relative
            top: 0

      // vertical center clear and arrow icon
      .ng-clear-wrapper,
      .ng-arrow-wrapper
        margin-top: -1em
