@use '../../mixins' as *

.bank-card
  display: flex
  flex-direction: column
  justify-content: space-between
  min-height: var(--bank-card-min-height)
  min-width: var(--bank-card-width)
  max-width: var(--bank-card-width)
  padding: 0
  width: 100%

  +mobile
    width: auto

  &.is-primary
    background: var(--bank-card-background-primary)

  &.has-error
    .box
      box-shadow: 0 2px 3px rgba(255, 56, 96, 0.5), 0 0 0 1px rgba(255, 56, 96, 1)

  // disable cards child, not card itself to prevent styling overlay
  .is-disabled
    opacity: var(--bank-card-opacity-disabled)

  // reset shrink to avoid huge bank card logo on initial render
  .media-left
    flex-shrink: inherit

  // adjust gap between switch toggle and label
  .media-right
    fino-switch > div
      margin-right: -8px

  // Sizing bank logos
  .image
    background-color: var(--bank-card-image-background-color)
    border-radius: var(--bank-card-image-radius)
    margin-left: auto
    overflow: hidden
    img
      max-height: 100%
      max-width: max-content

.bank-card-header
  border-top-left-radius: var(--bank-card-border-radius)
  border-top-right-radius: var(--bank-card-border-radius)
  padding: var(--bank-card-padding)

  &.media
    margin-bottom: 0

  &.is-primary
    background: var(--bank-card-background-primary)

.bank-card-body
  background-color: var(--bank-card-background-color)
  border-bottom-left-radius: var(--bank-card-border-radius)
  border-bottom-right-radius: var(--bank-card-border-radius)
  padding: var(--bank-card-padding)

  &.media
    border-top: 0
    margin: 0

// Bank cards layout
.bank-cards
  display: flex
  flex-direction: row
  flex-wrap: wrap
  justify-content: flex-start

  +mobile
    justify-content: center

  fino-bank-card
    margin: 1rem
