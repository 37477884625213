@use 'bulma/sass/form/shared' as form

fino-select,
.select:not(.is-multiple)
  height: auto

  &.is-fullwidth
    display: block

.control
  .ng-select,
  .ng-select.ng-select-single,
  .ng-select.ng-select-multiple
    .ng-select-container
      height: var(--input-height)
      background-color: var(--input-background-color)
      border-color: var(--input-border-color)
      border-radius: var(--input-radius)
      color: var(--input-color)

      .ng-value-container
        .ng-placeholder
          color: var(--input-placeholder-color)

        .ng-value,
        .ng-input,
        .ng-input > input
          color: var(--input-color)

    &:hover,
    &.is-hovered
      > .ng-select-container.input
        --input-border-l-delta: var(--input-hover-border-l-delta)

    &:active,
    &.is-active
      > .ng-select-container.input
        --input-border-l-delta: var(--input-active-border-l-delta)

    &.ng-select-focused,
    &.ng-select-opened
      > .ng-select-container.input
        border-color: hsl(var(--input-focus-h), var(--input-focus-s), var(--input-focus-l))
        border-radius: var(--input-radius)
        box-shadow: var(--input-focus-shadow-size) hsla(var(--input-focus-h), var(--input-focus-s), var(--input-focus-l), var(--input-focus-shadow-alpha))

    &.is-danger
      > .ng-select-container.input
        border-color: var(--input-danger-border-color) !important

      &.ng-select-focused,
      &.ng-select-opened
        > .ng-select-container.input
          border-color: var(--input-danger-border-color)
          box-shadow: var(--input-danger-box-shadow-size) var(--input-danger-box-shadow-color)

  // .control > .dropdown
  .dropdown-menu
    width: 100%
    min-width: 8rem

  .dropdown,
  .ng-select
    // Modifications
    &.is-danger
      .ng-dropdown-panel
        border-color: var(--input-danger-border-color)
        box-shadow: var(--input-danger-box-shadow-size) var(--input-danger-box-shadow-color)

    // Dropdown
    .ng-dropdown-panel
      // Animate dropdown panel
      animation-name: slideDown
      animation-duration: var(--transition-duration)
      animation-timing-function: var(--transition-timing-function)
      animation-fill-mode: forwards
      animation-iteration-count: 1
      border-color: var(--input-border-color)
      border-radius: var(--input-radius)
      box-shadow: var(--dropdown-panel-shadow)
      margin-top: var(--dropdown-panel-margin-top)
      transform-origin: top center

      // Colorize dropdown items
      .ng-dropdown-panel-items
        max-height: var(--dropdown-panel-items-max-height)
        padding: var(--dropdown-panel-items-padding)

        // Remove padding from disabled optgroup
        .ng-optgroup.ng-option-disabled
          padding: 0

        .ng-option
          background-color: hsl(var(--dropdown-item-h),var(--dropdown-item-s),calc(var(--dropdown-item-background-l) + var(--dropdown-item-background-l-delta)))
          color: hsl(var(--dropdown-item-h),var(--dropdown-item-s),var(--dropdown-item-color-l))
          transition-duration: var(--duration)
          transition-property: background-color, border-color, color

          &:last-child
            border-radius: 0

          // Not disabled
          &:not(.ng-option-disabled)
            // (marked = hover)
            &.ng-option-marked
              --dropdown-item-background-l-delta: var(--dropdown-item-hover-background-l-delta)
              --dropdown-item-border-l-delta: var(--dropdown-item-hover-border-l-delta)
              color: var(--dropdown-item-hover-color)

            // autocompleted by ng-option-highlight
            .highlighted
              --dropdown-item-background-l-delta: var(--dropdown-item-active-background-l-delta)
              --dropdown-item-border-l-delta: var(--dropdown-item-active-border-l-delta)
              color: var(--dropdown-item-active-color)

          // Disabled
          &.ng-option-disabled
            color: hsla(var(--dropdown-item-h),var(--dropdown-item-s),var(--dropdown-item-color-l), var(--dropdown-item-disabled-alpha))
            cursor: not-allowed

          // Selected
          &.ng-option-selected
            &,
            &.ng-option-marked
              --dropdown-item-h: var(--dropdown-item-selected-h)
              --dropdown-item-s: var(--dropdown-item-selected-s)
              --dropdown-item-l: var(--dropdown-item-selected-l)
              --dropdown-item-background-l: var(--dropdown-item-selected-background-l)
              --dropdown-item-color-l: var(--dropdown-item-selected-color-l)
        // never underline highlighted option
        .highlighted
          text-decoration: none
