@use 'bulma/sass/utilities/derived-variables' as dv
@use 'bulma/sass/utilities/css-variables' as cv
@use '../../mixins' as *

$button-colors: dv.$colors

.button
  font-weight: var(--button-weight)
  transition-property: transform, box-shadow
  transition-duration: var(--button-transition-duration)
  transition-timing-function: var(--button-transition-timing-function)
  will-change: transform

  // Colors
  @each $name, $pair in $button-colors
    &.is-#{$name}
      --button-outer-shadow-a: 0.05

  &:visited:not(.is-primary)
    color: currentColor

  // Styles only for buttons that are not disabled
  &:not(disabled),
  &:not(.is-disabled)
    // Hover
    &:hover, &.is-hover
      transform: translateY(-1px)

    // Active
    &:active, &.is-active
      transform: translateY(1px)

    // Focus
    &:focus, &.is-focus
      --button-outer-shadow-a: 0

  // disabled
  &[disabled],
  &.is-disabled
    opacity: 0.6
    cursor: not-allowed
    pointer-events: none

  // Reset delete button styles
  &.delete
    height: var(--delete-dimensions)
    padding: 0

  &.is-small
    height: auto

  // Vertical align center
  .icon
    line-height: 1
    // Right space between icon and text
    &:first-child:not(:last-child)
      margin-left: 0
      margin-right: 0.5em
    // Left space between text and icon
    &:last-child:not(:first-child)
      margin-left: 0.5em
      margin-right: 0

.has-addons
  .button
    height: var(--control-height)

  &.responsive
    +mobile
      display: block !important
      .control
        height: var(--control-height)
        &:not(:last-child)
          margin-right: 0px !important
          margin-bottom: -1px !important
        &:first-child:not(:only-child) .button
          border-top-right-radius: var(--radius) !important
          border-bottom-left-radius: 0 !important
        &:last-child:not(:only-child) .button
          border-top-right-radius: 0 !important
          border-bottom-left-radius: var(--radius) !important

.buttons
  &.has-min-width
    .button,
    .button-component,
    [fino-button]
      min-width: var(--button-min-width)
      +mobile
        min-width: 100%
