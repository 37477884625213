.panel-heading
  font-size: 1em

.button
  font-size: 0.8em

.tabs
  font-size: 0.8em

fino-input-control
  margin-bottom: 1rem